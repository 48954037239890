import { ReactNode } from "react";
import { AboutUsBioCard } from "components/LSRevamp/LSRevampInterfaces";

import AboutUsImageCard from "../AboutUsLandingPage/AboutUsImageCard";

type Props = {
  data: Array<AboutUsBioCard>;
  headerText: string | ReactNode;
};

function StudentTestimonialSection({ headerText, data }: Props) {
  return (
    <div className="mt-[2.75rem] mb-[5.5rem] md:max-w-[1200px] md:m-auto md:pt-[68px] md:pb-[80px]">
      <h2 className="font-bold text-2xl text-center text-[#1C1C1C] md:text-4xl mb-[48px] px-4">
        {headerText}
      </h2>
      <div className="flex overflow-x-scroll hide-scroll-bar px-5 md:items-center md:justify-center">
        <div className="flex flex-nowrap">
          {data.map((item: AboutUsBioCard, key: number) => (
            <AboutUsImageCard {...item} key={key} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default StudentTestimonialSection;
