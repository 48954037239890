import Image from "next/image";
import React from "react";

interface Props {
  img: string;
  name: string;
  degree?: string;
  work?: string;
  width: number;
  height: number;
  align: "start" | "center";
}

const AboutUsImageCard: React.FC<Props> = ({
  img,
  name,
  degree,
  work,
  width,
  height,
  align,
}) => {
  return (
    <div 
      className={`mx-3 relative text-white`}
      style={{ width: width + "px", textAlign: align }}
    >
      <Image
        src={img || ""}
        width={width}
        height={height}
        loading="lazy"
        alt="imagecard"
        layout="fixed"
      />
      <div
        className={`absolute bottom-0 left-0 w-full bg-gradient-to-b from-[#04010900] to-[#000000] py-10 rounded-[10px] mb-[5px]`}
      >
        <div
          className={`text-xl font-bold text-${align} ${
            align == "start" ? "ml-[10px]" : "ml-0"
          }`}
        >
          {name}
        </div>
        {degree && (
          <div
            className={`text-base text-${align} ${
              align == "start" ? "ml-[10px]" : "ml-0 text-[#DCDCDC]" 
            }`}
          >
            {degree}
          </div>
          )}
        {work && (<div className={`text-base  ml-[10px]`}>{work}</div>)}
      </div>
    </div>
  );
};

export default AboutUsImageCard;
